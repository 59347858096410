/* You can add global styles to this file, and also import other style files */
/* Bootstrap CSS CDN */

/* gerador */

.home-content-margin {
  margin-left: 232px;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.tpx-crud-header,
.tpx-crud-body {
  /* padding: 0;*/
  margin-bottom: 1rem !important;
}

.tpx-crud-footer .btn {
  margin-right: 14px;
}

.tpx-custom-modal {
  background-color: unset;
}

.tpx-custom-modal .modal-dialog {
  max-width: 75%;
}

.tpx-custom-modal-small .modal-dialog {
  max-width: 55%;
}

.tpx-custom-modal-compacto .modal-dialog {
  max-width: 400px;
}

.ngx-datatable .datatable-body .datatable-scroll {
  display: inherit;
}

.tpx-btn-cell {
  padding: 4px 2px;
}

.tpx-btn-action {
  font-size: 14px !important;
}

.t-header-title {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  color: var(--bs-body-color);
}

@media screen and (max-width: 576px) {
  .tpx-custom-modal .modal-dialog {
    max-width: 85% !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 1200px) {
  .tpx-custom-modal .modal-dialog {
    max-width: 85% !important;
  }
}
@media screen and (min-width: 1200px) {
  .tpx-custom-modal .modal-dialog {
    max-width: 75% !important;
  }
}
.tpx-custom-modal .modal-footer {
  justify-content: flex-start;
}

.tpx-borda {
  border: 1px solid #dee2e6 !important;
  border-color: #cbced1 !important;
  padding: 0.75rem 0.75rem 0 0.75rem;
  border-radius: 0.2rem;
}

.t-grid-btn {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
/* Bootstrap */
.btn {
  padding: 0.2rem 0.75rem;
}

.btn-close:focus {
  box-shadow: none;
  font-size: 14px;
}

.card {
  padding: 0 14px;
}

.tpx-nav-item {
  background-color: var(--bs-body-bg);
  padding: 2rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top: hidden;
  border-radius: var(--bs-border-radius);
}

.form-control {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  /*line-height: 1.5;*/
  border-radius: 0.2rem;
}

.form-group {
  margin-bottom: 0.25rem;
  line-height: 1.3;
}

/*
.form-custom {
  padding-bottom: 14px !important;
}
*/

.form-group .custom-radio {
  line-height: 1.42857143;
}

/* TOAST */
.toast {
  opacity: 1 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.toast-title {
  font-size: 16px !important;
}

.toast-message {
  font-size: 16px !important;
}

.header.header-6,
header.header-6 {
  background-color: #002d59;
}

.cor-padrao {
  color: #002d59 !important;
}

.ng-valid[required],
.ng-valid.required {
  border-radius: 0.2rem;
}

.ng-invalid:not(form, ngb-timepicker) {
  border-radius: 0.2rem;
}

.ng-select.is-invalid {
  border-left: 2px solid #9e2927 !important;
  border-radius: 0.2rem;
}

.tpx-crud-body input.form-control {
  border-left-width: 1px;
}

.breadcrump-container {
  padding: 0 14px 14px 14px;
}

.navbtn-menubutton {
  font-size: 1.25rem;
  color: var(--text-color) !important;
  transition: color var(--transition-duration);
}

.navbtn-menubutton:hover,
.navbtn-menubutton:focus {
  border: 1px solid #0d6efd !important;
  padding: 2px;
  border-radius: 0.2rem;
}

.principal-container {
  padding: 0;
}

.ngx-datatable.bootstrap .datatable-footer {
  background: transparent;
  color: var(--bs-dark);
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  background: white;
  color: var(--bs-dark);
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  vertical-align: top;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell .btn {
  font-size: 0.75rem;
  padding: 0.2rem 0.4rem;
  font-weight: 400;
}

body {
  color: var(--bs-secondary);
  background-color: #fff;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem !important;
}

.custom-select {
  font-size: unset;
}

.custom-control label {
  font-weight: inherit;
}

#body-row {
  margin-top: 100px;
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  #body-row {
    margin-top: 100px;
  }
}

.filter-icon {
  cursor: pointer;
  color: darkgrey;
}

.painel-card-columns {
  column-count: 1 !important;
}

.painel-card-columns-1 {
  column-count: 1 !important;
}

@media (min-width: 576px) {
  .painel-card-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 768px) {
  .painel-card-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 992px) {
  .painel-card-columns {
    column-count: 2 !important;
  }
}

@media (min-width: 1200px) {
  .painel-card-columns {
    column-count: 2 !important;
  }
}

.painel-card {
  background-color: white;
}

.painel-card-title {
  font-size: 18px;
  color: white;
  background-color: #322f6a;
  padding: 4px 14px;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: 0 -15px;
}

.panel-row {
  display: flex;
  flex-wrap: wrap;
}

.painel-card-body {
  padding: 14px;
}
/* .painel-card-body {
  
} */

.form-control-plaintext {
  cursor: default;
}

.form-control-plaintext:focus {
  border-color: unset !important;
  box-shadow: unset !important;
  border-width: 0 !important;
}

.ng-select .ng-select-container {
  background-color: #fff;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  min-height: 38px;
  align-items: center;
}

.ng-select.ng-select-single .ng-select-container {
  height: 38px;
}

.ng-select.custom {
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.2rem;
}

.ng-select.custom .ng-select-container {
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.2rem;
}

.ng-select-append {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  color: var(--bs-body-color);
  text-align: center;
  background-color: var(--bs-tertiary-bg);
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-color: var(--bs-border-color);
  border-top-style: solid;
  border-top-width: var(--bs-border-width);
  border-right-color: var(--bs-border-color);
  border-right-style: solid;
  border-right-width: var(--bs-border-width);
  border-bottom-color: var(--bs-border-color);
  border-bottom-style: solid;
  border-bottom-width: var(--bs-border-width);
  border-radius: var(--bs-border-radius);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-select-group {
  height: 38px;
}

.ng-select-group .ng-select .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tpx-toggle-split {
  min-width: 32px;
}

#sidebar-container.hide {
  display: none !important;
}

.tpx-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #9e2927;
}

.invalid-feedback {
  color: #9e2927;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #9e2927;
}

.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #9e2927;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px #fde6e8;
}

.vertical_label_text {
  color: #ffffff;
  display: inline-block !important;
  overflow-wrap: normal !important;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space: nowrap !important;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  cursor: pointer;
  -webkit-writing-mode: vertical-lr;
  -moz-writing-mode: vertical-lr;
  -ms-writing-mode: tb-rl;
  -o-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.w-num {
  width: 4rem;
}

@media (max-width: 576px) {
  .w-ctrl {
    min-width: 100%;
  }
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  outline-color: lightgreen;
}

.modal-header {
  display: flex !important;
  border-bottom: 1px solid var(--bs-modal-border-color);
  background-color: rgb(239, 243, 247) !important;
  color: var(--bs-secondary) !important;
}

.modal-header,
.modal-body,
.modal-footer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.zindex {
  z-index: 99000 !important;
}

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.ag-root,
.ag-body-viewport,
.ag-body-viewport-wrapper {
  overflow: visible !important;
}

.btn-sm {
  line-height: 1 !important;
}

.table-wrapper ngx-datatable {
  max-width: 98%;
}

.table-wrapper {
  display: block;
  min-width: 400px;
  overflow-x: auto;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active .btn-link {
  color: #fff !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active .btn-outline-dark {
  color: #fff !important;
  border-color: #fff;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: rgb(208, 212, 214);
  font-weight: bold;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  text-align: left !important;
  vertical-align: middle !important;
  font-weight: 700 !important;
}

@keyframes envelope {
  50% {
    content: '\f2b6';
  }
}

/*remove comment to show the effect only on hover */
.fa-animation/*:hover*/:before {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.fa-animation-envelope:before {
  animation-name: envelope;
}

.toast-top-right {
  top: 62px;
  right: 12px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-body-color);
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

@media (min-width: 576px) {
  .tpx-custom-modal {
    margin-top: 64px;
  }
}

.card {
  margin-bottom: 14px !important;
}

.canvas-tpx {
  background: #e9ecef;
}

.tpx-chart-title {
  font-size: 1.2em;
  font-weight: 700;
}

.chart-container {
  height: 185px;
}

.chart-combo-box {
  min-height: 48px;
}

.chart-combo-item {
  max-width: 400px;
}

.cursor-pointer {
  cursor: pointer;
}

.ngb-dp-weekday {
  color: var(--bs-dark) !important;
  font-weight: 500;
}

.ngb-dp-weekdays div:nth-child(7) {
  color: var(--bs-danger) !important;
  font-weight: 500;
}

.tpx-crud-card:last-child {
  margin-bottom: 0;
}

.tpx-crud-card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  box-shadow: var(--card-shadow);
  border-radius: 12px;
}

.tpx-border-botao {
  border-bottom-left-radius: unset !important;
  border-top-left-radius: unset !important;
}

.p-viewer {
  float: right;
  margin-top: -29px;
  margin-right: 10px;
  position: relative;
  color: var(--bs-dark);
  cursor: pointer;
}

.logo-fonte {
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 150%;
  font-weight: bold;
  font-style: italic;
}

.logo-image {
  height: 60px;
}

/** */
.panel-splash-fundo {
  background-image: url('/assets/images/splash-page-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 100vh;
}

.cardshadow {
  box-shadow:
    0 8px 12px 0 rgba(0, 0, 0, 0.5),
    0 6px 20px 0 rgba(0, 0, 0, 0.8);
  padding: 3rem;
  background-color: white;
  min-width: 30rem;
}

@media screen and (max-width: 767px) {
  .panel-splash-fundo {
    background: unset;
    padding-left: 1rem;
    padding-right: 1rem;
    height: unset;
  }

  .cardshadow {
    box-shadow: unset !important;
    border-radius: unset !important;
    padding: 0.75rem;
    min-width: 20rem;
  }
}

html,
body {
  display: block;
  background-color: whitesmoke;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-column-select {
  overflow: initial;
}

/* RESPONSIVO - TRANSFORMA PARA LISTA */

mat-row:nth-child(even) {
  background-color: white;
}

mat-row:nth-child(odd) {
  background-color: white;
}

mat-row,
mat-footer-row {
  min-height: 28px !important;
}

mat-table {
  overflow: auto;
}

.mobile-label {
  display: none;
}

@media (max-width: 800px) {
  .mobile-label {
    width: 80px;
    display: inline-block;
    font-weight: bold;
  }

  mat-header-row {
    display: none !important;
  }

  mat-row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
  }

  mat-cell:first-of-type {
    padding-left: 16px !important;
  }
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.2;
}

.footer-button {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.footer-button-height {
  min-height: 6rem;
}

.required label {
  font-weight: normal;
}
.required label:after {
  color: var(--bs-secondary);
  content: ' *';
  display: inline;
}

.not-required label {
  font-weight: unset;
}

.not-required label:after {
  color: unset;
  content: unset;
  display: inline;
}

.xcontainer {
  display: flex;
  height: 38px;
}

.xcontainer-img {
  width: 28px;
  height: 28px;
}

.xcontainer-img-lg {
  width: 58px;
  height: 58px;
}

.ellipse-dropdown .dropdown-toggle::after {
  display: none;
}

.btn-ellipse {
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.btn-ellipse i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ellipse:hover,
.btn-ellipse:focus,
.btn-ellipse:not(:disabled):not(.disabled):active,
.btn-ellipse:not(:disabled):not(.disabled).active,
.show > .btn-ellipse.dropdown-toggle {
  color: var(--bs-body-color);
  background-color: whitesmoke;
  border-color: blue;
}

ng-select.required.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px #fde6e8;
}

ng-select.required.ng-invalid.ng-untouched .ng-select-container {
  border-color: #dc3545;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px #fde6e8;
}

.icon-bar {
  width: 100%;
  background-color: #555;
  overflow: auto;
}

.icon-bar a {
  float: left;
  width: 20%;
  text-align: center;
  padding: 12px 0;
  transition: all 0.3s ease;
  color: white;
  font-size: 36px;
}

.icon-bar a:hover {
  background-color: #000;
}

.char-counter {
  display: block;
  text-align: right;
  color: gray;
}

.card-equal-height {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.text-content {
  display: block; /* Garante que o conteúdo seja exibido como um bloco */
  max-height: 100%; /* Limita a altura máxima para evitar overflow */
  word-break: break-all; /* Quebra palavras longas */
  overflow: hidden; /* Esconde qualquer conteúdo que ultrapasse as bordas */
  text-overflow: ellipsis; /* Adiciona reticências (...) ao texto que ultrapassa as bordas */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
  gap: 1rem; /* Espaçamento entre os cards */
}

.w-card {
  width: 20.5rem; /* deve combinar com card-grid */
}

.w-error-ellipsis {
  max-width: 30rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-error-label {
  min-width: 5rem;
  display: inline-block;
  font-weight: bold;
}

.card-title {
  font-weight: bold;
  max-width: 16rem;
  white-space: nowrap;
}

.invalid-color {
  color: #9e2927;
}

.pill-button {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  color: var(--bs-body-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  cursor: pointer;
  border-radius: var(--bs-border-radius-lg) !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.pill-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  color: var(--bs-body-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  border-radius: var(--bs-border-radius-lg) !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.pill-button:hover,
.pill-text:hover {
  border: 1px solid #000;
}

.pill-ellipsis {
  max-width: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tpx-cel-grid {
  min-height: 1.5rem;
  display: grid;
  align-items: center;
}

.tpx-tooltip .tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.tpx-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--bs-tooltip-bg) !important;
}

.cell-container {
  position: relative; /* Define o `mat-cell` como referência para o painel */
  overflow: visible; /* Permite que o conteúdo exceda os limites */
}